import { render, staticRenderFns } from "./LoginView.vue?vue&type=template&id=aefdc540&scoped=true"
import script from "./LoginView.vue?vue&type=script&lang=js"
export * from "./LoginView.vue?vue&type=script&lang=js"
import style0 from "./LoginView.vue?vue&type=style&index=0&id=aefdc540&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.4.34_css-loader@6.11.0_webpack@5.93.0__lodash@4.17.21__wxgce77dfilg3eov64uv6ejrjm/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aefdc540",
  null
  
)

export default component.exports